import { useStaticQuery, graphql } from "gatsby";

function PrimaryColor() {
  const data = useStaticQuery(graphql`
    query PrimaryColorQuery {
      sanitySiteSettings {
        primaryColor
      }
    }
  `);
  return data.sanitySiteSettings.primaryColor;
}
export default {
  fonts: {
    primary: "Poppins",
    header: "Poppins",
  },
  fontWeights: {
    light: 300,
    normal: 400,
    semibold: 600,
    bold: 700,
  },
  fontSizes: {
    body: "1.2rem",
  },
  lineHeights: {
    body: "1.375rem",
    heading: "1.2",
  },
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  colors: {
    background: "#fff",
    primary: PrimaryColor,
    primaryDark: "#0b5d57",
    blue: "#4c00a9",
    gray: "#8e8d8f",
    darkGray: "#190A32",
    textGray: "#666666",
    lightGray: "#E8E6E6",
  },
  breakpoints: ["800px", "1000px", "1234px"],
  buttons: {
    primarySolid: {
      fontSize: "0.875rem",
      borderRadius: "1.5rem",
      color: "white",
      textDecoration: "none",
      cursor: "pointer",
      lineHeight: "1.5rem",
      display: "inline-block",
      padding: "0.7rem 2.5rem",
      lineHeight: "1.5rem",
      position: "relative",
      fontWeight: 600,
      zIndex: "1",
      background:
        "linear-gradient(to right, #0b5d57 0%, #00a998 98%, #00a998 100%)",
      boxShadow: "0px 15px 30px rgb(0 169 152 / 27%)",
      "&:before": {
        content: "''",
        position: "absolute",
        top: "2px",
        left: "2px",
        right: "2px",
        bottom: "2px",
        borderRadius: "1.5rem",
        background: "white",
        zIndex: "-1",
        display: "none",
      },
      ":hover": {
        color: "darkGray",
        boxShadow: "none",
        "&:before": {
          display: "block",
        },
      },
    },
    primaryLight: {
      fontSize: "0.875rem",
      borderRadius: "1.5rem",
      color: "darkGray",
      textDecoration: "none",
      cursor: "pointer",
      lineHeight: "1.5rem",
      display: "inline-block",
      padding: "0.7rem 2.5rem",
      lineHeight: "1.5rem",
      position: "relative",
      fontWeight: 600,
      zIndex: "1",
      background:
        "linear-gradient(to right, #0b5d57 0%, #00a998 98%, #00a998 100%)",
      "&:before": {
        content: "''",
        position: "absolute",
        top: "2px",
        left: "2px",
        right: "2px",
        bottom: "2px",
        borderRadius: "1.5rem",
        background: "white",
        zIndex: "-1",
      },
      ":hover": {
        color: "white",
        "&:before": {
          display: "none",
        },
        background:
          "linear-gradient(to right, #0b5d57 0%, #00a998 98%, #00a998 100%)",
        boxShadow: "0px 15px 30px rgb(0 169 152 / 27%)",
      },
    },
  },
};
